import * as React from 'react';
import { withRouter, WithRouterProps, browserHistory } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import { __, __r } from 'react-i18n';
import {
  MetaTags,
  CartStepper,
  CartSummary,
  CartFooter,
  FlexRow,
  FlexCol,
  ZemplinLoaderWrapper,
  Loader,
  InfoText,
} from 'eshop-defaults';
import {
  cartIndexRoute,
  cartSecondStepRoute,
  cartThirdStepRoute,
  CART_ROUTES,
  cartHalfStepRoute,
} from './utilities';
import { RootState } from '../../rootReducer';
import {
  cartDataSelector,
  cartIsFetchingSelector,
  updateCart,
  resetCart,
  showProblemsInCart,
  fetchCartSuccess,
  startFetchingCart,
  stopFetchingCart,
} from './cartSlice';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import { prop } from '../../utilities';
import { removeCartCookie } from './helpers';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { useForm } from '../../utilities/hooks';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { USER_COOKIE } from 'react-auth';
import * as cookie from 'react-cookies';
import Modal from '../../components/_helpers/Default/Modal';

const CART_STAGES = [
  { title: () => __('Nákupný košík'), stepNumber: 1, stepUrl: cartIndexRoute },
  {
    title: () => __('Prihlásenie'),
    stepNumber: 1.5,
    stepUrl: cartHalfStepRoute,
  },
  {
    title: () => __('Osobné údaje'),
    stepNumber: 2,
    stepUrl: cartSecondStepRoute,
  },
  {
    title: () => __('Dodanie a platba'),
    stepNumber: 3,
    stepUrl: cartThirdStepRoute,
  },
];

interface Address {
  city: string;
  company: string;
  country: string;
  country_code: string;
  country_id: string;
  person: {
    name: string;
    surname: string;
    email: string;
    phone: string;
  };
  street: string;
  street_number: string;
  zip: string;
}

interface DeliveryAddress extends Address {
  delivery_address_id: number;
}

interface Props {
  children: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  error: { details: { description: string } };
  userInfo: any;
  deliveryAddresses: any;
  deliveryAddressesIsFetching: boolean;
  dispatch: any;
  handleAcceptTermsChange: (e: any) => void;
  handleAcceptHeurekaChange: (e: any) => void;
  handleAcceptNewsLetterChange: (e: any) => void;
  createOrder: () => Promise<string>;
  freeDeliveryRemainingAmount: number | null;
  showProblems: boolean;
  handleUsePointsChange: (points: any) => void;
}

function CartLogic({
  children,
  location: { pathname },
  router,
  data,
  error,
  userInfo,
  isFetching,
  deliveryAddresses,
  deliveryAddressesIsFetching,
  dispatch,
  handleAcceptTermsChange,
  handleAcceptHeurekaChange,
  handleAcceptNewsLetterChange,
  createOrder,
  freeDeliveryRemainingAmount,
  showProblems,
  handleUsePointsChange,
}: Props & WithRouterProps) {
  const { items } = data || {};
  const [step, setStep] = React.useState(1);
  const [isMovingToNextStep, setIsMovingToNextStep] = React.useState(false);

  const contactInfoInitialState = {
    billing_address: prop(data, 'billing_address'),
    company: prop(data, 'company'),
    delivery_address: prop(data, 'delivery_address'),
    billing_company: prop(data, 'billing_company'),
    use_delivery_address: prop(data, 'use_delivery_address'),
    note: prop(data, 'note'),
  };

  const [cartContactInfo, dispatchCartContactInfo] = useForm<{
    billing_address: Address;
    company: { name: string; ic_dph: string; ico: string; dic: string };
    delivery_address: DeliveryAddress;
    billing_company: boolean;
    use_delivery_address: boolean;
    note: string;
  }>(contactInfoInitialState);

  const [openGlsModal, setOpenGlsModal] = React.useState(false);

  const handleStepChange = async (newStep: number) => {
    if (newStep !== step) {
      if (newStep === 4) {
        dispatch(startFetchingCart());
        const publicId = await createOrder();
        if (publicId) {
          removeCartCookie();
          await dispatch(loginUser(cookie.load(USER_COOKIE)));
          await dispatch(resetCart());
          // create order
          router.push(
            `${__r(
              'routes:dokoncena-objednavka',
              '/dokoncena-objednavka',
            )}/${publicId}`,
          );
        } else {
          dispatch(stopFetchingCart());
        }
      } else {
        let canMoveToNext = true;
        if (data) {
          const newData: any = {
            ...data,
            step: newStep,
            billing_address: cartContactInfo.billing_address,
            company: cartContactInfo.company,
            delivery_address: {
              ...cartContactInfo.delivery_address,
              person:
                cartContactInfo.delivery_address.delivery_address_id &&
                cartContactInfo.delivery_address.person &&
                cartContactInfo.delivery_address.person.name
                  ? cartContactInfo.delivery_address.person
                  : cartContactInfo.billing_address.person,
            },
            note: cartContactInfo.note,
            use_delivery_address: cartContactInfo.use_delivery_address,
            billing_company: cartContactInfo.billing_company,
          };

          try {
            const res = await API.updateCart(data.id, {}, newData);
            if (res && res.problems) {
              if (newStep === 3) {
                canMoveToNext = !res.problems.some(p => p.type === 'form');
                if (!canMoveToNext) {
                  dispatch(showProblemsInCart(true));
                }
              } else if (newStep === 2) {
                canMoveToNext = !res.problems.some(p => p.type === 'cart');
                if (!canMoveToNext) {
                  dispatch(showProblemsInCart(true));
                }
              }
              dispatch(fetchCartSuccess({ cart: res }));
              window.scrollTo({
                behavior: 'smooth',
                top: 200,
              });
            }
          } catch (err) {}
        }

        if (canMoveToNext) {
          dispatch(showProblemsInCart(false));
          setStep(newStep);
          setIsMovingToNextStep(false);
          router.push(
            (CART_ROUTES[newStep]() as string) || (cartIndexRoute() as string),
          );
        }
      }
    }
  };

  // React.useEffect(() => {
  //   setStep(initialStep);
  //   const newRoute = CART_ROUTES[initialStep];
  //   console.log({ initialStep, newRoute, router });
  //   // router.push(newRoute);
  //   dispatch(push(newRoute));
  // }, [initialStep, router]);

  React.useEffect(() => {
    let step = 1;
    if (pathname === (cartSecondStepRoute() as string)) {
      step = 2;
    } else if (pathname === (cartThirdStepRoute() as string)) {
      step = 3;
    } else if (pathname === (cartHalfStepRoute() as string)) {
      step = 1.5;
    }

    setStep(step);
    const newRoute = CART_ROUTES[step];
    dispatch(setBreadCrumbPath(BreadCrumbType.CART, { url: newRoute }));
    router.push((newRoute() as string) || (cartIndexRoute() as string));
  }, [pathname, router, dispatch]);

  const handleSendToEmailClick = () => {
    // send to email
    console.log('sending to email');
  };

  if (isFetching && !data) {
    return <ZemplinLoaderWrapper height={500} />;
  }

  const onChooseGls = point => {
    if (point.id) {
      closeGlsModal();
      const cart = data;
      const lang = prop(cart, 'lang', 'sk');
      const deliveryId = lang === 'cz' ? 'GLS_BALIKOMAT_CZ' : 'GLS_BALIKOMAT';

      dispatch(
        updateCart({
          ...data,
          delivery: { id: deliveryId },
          pickup_id: `${point.id}`,
          pickup_payload: point,
        }),
      );
    }
  };

  const openGls = () => {
    if (window.GlsWidget && typeof window.GlsWidget.open === 'function') {
      setOpenGlsModal(true);
      setTimeout(() => {
        window.GlsWidget.open(onChooseGls, { find: 1, renderTo: '#my-plugin' }); // renderTo musí ostať ako je, pretoťže gls widget je nastavený len na toto id
      }, 150);
    }
  };

  const closeGlsModal = () => {
    setOpenGlsModal(false);
    window.GlsWidget.close();
  };

  const isEmpty = !items || items.length === 0;
  const childrenWithProps = React.cloneElement(children, {
    data,
    isFetching,
    deliveryAddressesIsFetching,
    deliveryAddresses,
    freeDeliveryRemainingAmount,
    showProblems,
    dispatchCartContactInfo,
    cartContactInfo,
    openGlsWidget: openGls,
    handleStepChange,
  });

  const user = prop(data, 'user_id', false);

  return (
    <>
      {!isEmpty && (
        <CartStepper
          steps={CART_STAGES}
          setCartStep={handleStepChange}
          currentStepNumber={step}
          pathname={pathname}
          onStepClick={handleStepChange}
        />
      )}{' '}
      {openGlsModal && (
        <Modal
          size="big"
          showX={true}
          closeModal={() => closeGlsModal()}
          style={{ height: '98%', width: '98%' }}
        >
          <GlsWrapper id="my-plugin">{__('Načitávam..')} </GlsWrapper>
        </Modal>
      )}
      <Wrapper className="container container--wide" step={step}>
        <MetaTags tags={{ title: __('Košík') }} />
        {/* {error && (
          <InfoText
            type={InfoType.ERROR}
            info={`${__(
              'Nastala chyba pri získavaní košíka',
            )}: ${error.details && error.details.description}`}
          />
        )} */}
        {isEmpty || step === 1 ? (
          <SummaryContentWrapper step={step}>
            {childrenWithProps}{' '}
            <Loader
              style={{
                position: isFetching ? 'absolute' : 'initial',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 6,
              }}
              loading={isFetching}
              dim={true}
            />
          </SummaryContentWrapper>
        ) : (
          <SummaryContentWrapper step={step}>
            <ContentWrapper step={step}>{childrenWithProps}</ContentWrapper>
            <CartSummary
              handleAcceptTermsChange={handleAcceptTermsChange}
              handleAcceptHeurekaChange={handleAcceptHeurekaChange}
              handleAcceptNewsLetterChange={handleAcceptNewsLetterChange}
              userInfo={userInfo}
              data={data}
              handleStepChange={handleStepChange}
              step={step}
              isFetching={isFetching || isMovingToNextStep}
              freeDeliveryRemainingAmount={freeDeliveryRemainingAmount}
              handlePointsChange={handleUsePointsChange}
            />

            {step === 3 && (
              <Loader
                style={{
                  position: isFetching ? 'absolute' : 'initial',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 6,
                  display: isFetching ? 'block' : 'none',
                }}
                loading={isFetching}
                dim={true}
              />
            )}
          </SummaryContentWrapper>
        )}
        {!isEmpty && step === 1 && (
          <CartFooter
            handleStepChange={handleStepChange}
            handleSendToEmailClick={handleSendToEmailClick}
            user={user}
          />
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(FlexCol)<{ step: number }>`
  width: 100%;
  padding: ${({ step }) =>
    step === 1.5 ? `${rem(0)} ${rem(24)}` : `${rem(56)} ${rem(24)}`};
  position: relative;

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: ${({ step }) =>
      step === 1.5 ? rem(0) : `0 ${rem(24)} ${rem(32)}`};
  `}
`;

const SummaryContentWrapper = styled(FlexRow)<{ step: number }>`
  width: 100%;
  justify-content: ${({ step }) => (step === 1.5 ? 'center' : 'space-between')};
  max-width: ${rem(1148)};
  margin: 0 auto;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     flex-flow: column;
  `}
`;

const ContentWrapper = styled(FlexCol)<{ step: number }>`
  width: 100%;
  justify-content: flex-start;
  max-width: ${({ step }) => (step === 1.5 ? rem(800) : rem(688))};

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     margin: 0 auto;
  `}
`;

const GlsWrapper = styled.div`
  margin-top: ${rem(26)};
  width: calc(100% + ${rem(50)});
  height: calc(100% - ${rem(10)});
  margin-left: ${rem(-25)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.colors.black};
  position: relative;

  #glsWidgetIframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export default withRouter(CartLogic);
