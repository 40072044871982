import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  GolfcentrumTitle as Title,
  InfoText,
  Invoices,
  ZemplinSelect as Select,
  Orders,
  PaginationSimple,
  ClubUserBlock,
  GolfcentrumBlockWithTitle as BlockWithTitle,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';

interface Props {
  invoices: any;
  orders: ThenArg<typeof API.getOrders>;
  data: any;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  handleRefresh: (e) => void;
}

function MyOrders({
  currentId,
  orders,
  data,
  invoices,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  handleRefresh,
}: Props) {
  const { limit, offset, total } = ordersData;

  return (
    <Wrapper>
      {data.data ? (
        <>
          {data.data.card_number || data.data.individualna_zlava ? (
            <BlockWithTitle title={__('Vernostná karta')}>
              <ClubUserBlock
                cardnumber={data.data.card_number}
                zlava={data.data.individualna_zlava}
              />
            </BlockWithTitle>
          ) : data.data.trust_points !== undefined ? (
            <BlockWithTitle title={__('Vernostné body')}>
              <ClubUserBlock
                cardnumber={data.data.card_number}
                body={data.data.trust_points}
              />
            </BlockWithTitle>
          ) : null}
        </>
      ) : null}

      {/* faza 2 - uprava API + napojenie na frontende */}
      {false && (
        <>
          <Title marginTop={0} marginBottom={32}>
            {__('Neuhradené faktúry')}
          </Title>
          <InfoText
            type={'ERROR'}
            info={'Máte 1 neuhradenú faktúru po splatnosti.'}
          />
          <Invoices isFetching={false} invoices={invoices} />
        </>
      )}

      <Title marginTop={0} marginBottom={32}>
        {__('Moje objednávky')}
      </Title>
      {options && options.length > 1 && (
        <Select
          value={currentId}
          options={options}
          onChange={handleSelectChange}
        />
      )}
      <Orders
        isFetching={isFetchingOrders}
        orders={orders}
        handleRefresh={handleRefresh}
      />
      <PaginationSimple
        totalItems={total}
        limit={limit}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MyOrders;
