import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags, LoginCartHalfstep } from 'eshop-defaults';
import { WithRouterProps } from 'react-router';
import {
  resolveBaseApiUrlByLang,
  resolveBaseUrlByLang,
} from '../../configureTrans';
import { cartIdSelector } from '../Cart/cartSlice';
import { langSelector } from '../Category/selectors';
import styled from '../../theme/styled-components';
import { rem } from 'polished';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
  lang: string;
  handleStepChange: (step: number) => void;
}

interface State {
  error: string;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: auto;
  margin: ${rem(80)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: 0 ${rem(16)};
     margin: ${rem(32)} auto;
  `}
`;

class CartHalfstep extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  public render() {
    const {
      cartId,
      location: { pathname, search },
      lang,
      handleStepChange,
    } = this.props;
    const { error } = this.state;

    return (
      <>
        {' '}
        <Wrapper>
          <MetaTags
            tags={{ title: __('Košík - Prihlásenie | GOLF CENTRUM.sk') }}
          />
          <LoginCartHalfstep
            baseApiUrl={resolveBaseApiUrlByLang(lang)}
            baseUrl={resolveBaseUrlByLang(lang)}
            cartId={cartId}
            pathname={pathname}
            search={search}
            error={error}
            lang={lang}
            handleStepChange={handleStepChange}
          />{' '}
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartId: cartIdSelector(state),
    lang: langSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartHlafstep' })(CartHalfstep),
);
