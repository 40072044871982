import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags } from 'eshop-defaults';
import {
  ordersIsFetchingSelector,
  fetchOrders,
  ordersByIdSelector,
  fetchChildUsers,
  ordersDataSelector,
  childUsersSelector,
  fetchCustomerDetails,
} from './myAccountSlice';
import API, { ThenArg } from '../../services/API';
import MyOrders from '../../components/MyAccount/MyOrders';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';

const INVOICES = [
  {
    invoiceNr: '401902305',
    sumPrice: '9999,99',
    invoicePath: '',
    invoiceFileName: '401902305.pdf',
    notPaid: true,
  },
  {
    invoiceNr: '401902305',
    sumPrice: '9999,99',
    invoicePath: '',
    invoiceFileName: '401902305.pdf',
  },
];

const LIMIT = 5;

interface Props {
  user: any;
  dispatch: any;
  isFetchingOrders: boolean;
  ordersById: object;
  ordersData: any;
  childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  accountInfo: any;
}

interface State {
  currentId: string;
  offset: number;
}

class MyOrdersContainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_ORDERS));
      await Promise.all([
        dispatch(fetchCustomerDetails()),
        dispatch(fetchChildUsers()),
        dispatch(fetchOrders(null, LIMIT, 0)),
      ]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
      offset: 0,
    };
  }

  public render() {
    const {
      user,
      ordersById,
      ordersData,
      childUsers,
      isFetchingOrders,
      accountInfo,
    } = this.props;
    const { currentId } = this.state;
    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: (user.id || '').toString(),
          }))
          .map(a => a)
      : [];
    const options = [
      { name: 'Ja', value: user.id.toString() },
      ...childUserOptions,
    ];
    return (
      <>
        <MetaTags
          tags={{
            title: __('Moj účet - Moje objednávky a faktúry | GOLF CENTRUM.sk'),
          }}
        />
        <MyOrders
          orders={ordersById[currentId]}
          invoices={INVOICES}
          ordersData={ordersData}
          data={accountInfo}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetchingOrders={isFetchingOrders}
          handleRefresh={this.handleRefresh}
        />
      </>
    );
  }

  public handleSelectChange = e => {
    const id = e.target.value;
    this.setState({ ...this.state, currentId: id });
    this.props.dispatch(fetchOrders(id, LIMIT, 0));
  };

  public handleOffsetChange = e => {
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;
    const offset = LIMIT * num;
    this.setState({ ...this.state, offset });
    this.props.dispatch(fetchOrders(currentId, LIMIT, offset));
  };
  public handleRefresh = () => {
    try {
      const { currentId } = this.state;
      const { offset } = this.state;
      this.props.dispatch(fetchOrders(currentId, LIMIT, offset));
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isFetchingOrders: ordersIsFetchingSelector(state),
    ordersById: ordersByIdSelector(state),
    ordersData: ordersDataSelector(state),
    childUsers: childUsersSelector(state),
    accountInfo: state.myAccount.userInfo,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyOrdersContainer' })(MyOrdersContainer),
);
