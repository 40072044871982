import { __r } from 'react-i18n';
import API from '../../services/API';
import { langSelector, currencySelector } from '../App/selectors';
import { saveCartCookie } from './helpers';

export enum cartRoutes {
  INDEX = 'routes:kosik',
  HALF_STEP = 'routes:kosik/prihlasenie',
  SECOND_STEP = 'routes:kosik/osobne-udaje',
  THIRD_STEP = 'routes:cart/dodanie-a-platba',
}

export const cartIndexRoute = () => __r('routes:kosik', '/kosik');
export const cartHalfStepRoute = () =>
  __r('routes:kosik/prihlasenie', '/kosik/prihlasenie');
export const cartSecondStepRoute = () =>
  __r('routes:kosik/osobne-udaje', '/kosik/osobne-udaje');
export const cartThirdStepRoute = () =>
  __r('routes:cart/dodanie-a-platba', '/kosik/dodanie-a-platba');

export const CART_ROUTES = {
  1: cartIndexRoute,
  1.5: cartHalfStepRoute,
  2: cartSecondStepRoute,
  3: cartThirdStepRoute,
};

export const createNewCart = async (state, API) => {
  console.log('creating cart');
  const { id } = await API.createCart(
    {},
    {
      xAcceptLanguage: langSelector(state),
      xCurrency: currencySelector(state),
    },
  );
  saveCartCookie(id);

  return id;
};
